import one from '../assets/svg/projects/one.svg'
import two from '../assets/svg/projects/two.svg'
import three from '../assets/svg/projects/three.svg'
import four from '../assets/svg/projects/four.svg'
import five from '../assets/svg/projects/five.svg'
import six from '../assets/svg/projects/six.svg'
import seven from '../assets/svg/projects/seven.svg'
import eight from '../assets/svg/projects/eight.svg'


export const projectsData = [
    {
        id: 1,
        projectName: 'Developer Portfolio',
        projectDesc: 'This is the current site you are on. I cloned the repository and customized the code to fit my data and portfolio needs.',
        tags: ['HTML' ,'CSS', 'Javascript', 'React', 'NodeJS'],
        code: 'https://github.com/medudzinski/developer-portfolio',
        demo: '',
        image: "https://raw.githubusercontent.com/medudzinski/developer-portfolio/b227d262e5e860b4dc84d544968a05f8b13623a7/dp-site.png"
    },]
    /*{
       id: 2,
        projectName: '',
        projectDesc: '',
        tags: [],
        code: '',
        demo: '',
        image: ""
    },
    {
        id: 3,
        projectName: '',
        projectDesc: '',
        tags: [],
        code: '',
        demo: '',
        image: ""
    },
    {
        id: 4,
        projectName: '',
        projectDesc: '',
        tags: [],
        code: '',
        demo: '',
        image: ""
    },
    {
        id: 5,
        projectName: '',
        projectDesc: '',
        tags: [],
        code: '',
        demo: '',
        image: ''
    },
    
]


// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/