export const socialsData = {
    github: 'https://github.com/medudzinski',
    facebook: '',
    linkedIn: 'https://www.linkedin.com/in/morgan-dudzinski-capm%C2%AE%EF%B8%8F-262594105/',
    instagram: '',
    codepen: '',
    twitter: '',
    reddit: '',
    blogger: '',
    medium: '',
    stackOverflow: '',
    gitlab: '',
    youtube: ''
}