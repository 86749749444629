/* eslint-disable */
import { BiShoppingBag, BiPencil, BiWindows } from "react-icons/bi";
import { BsCodeSlash, BsClipboardData } from "react-icons/bs";
import { AiOutlineMail, AiFillAudio } from "react-icons/ai";
import { FaChrome,  FaChalkboardTeacher, FaCameraRetro, FaPinterest, FaVideo, FaTabletAlt, FaRegNewspaper, FaProjectDiagram, FaHubspot } from "react-icons/fa";

 

export const servicesData = [
    {
        id: 1,
        title: 'Web Design',
        icon: <BsCodeSlash />
    },
    {
        id: 2,
        title: 'Content Writing',
        icon: <BiPencil /> 
    },
    {
        id: 3,
        title: 'Email Marketing',
        icon: <AiOutlineMail />
    },
     {
         id: 4,
         title: 'Internet Research',
         icon: <FaChrome />
     },
    {
        id: 5,
        title: 'SEO',
        icon: <BiWindows />
    },
    {
        id: 6,
        title: 'Marketing',
        icon: <BiShoppingBag/>
    },
    {
        id: 7,
        title: 'Project Management',
        icon: <FaProjectDiagram />
    },
    // {
    //     id: 8,
    //     title: 'Pinterest Virtual Assistant',
    //     icon: <FaPinterest />
    // }, 
    {
        id: 9,
        title: 'HubSpot',
        icon: <FaHubspot/>
    },
    // {
    //     id: 10,
    //     title: 'Data Entry', 
    //     icon: <BsClipboardData />
    // },
    /*{
        id: 11,
        title: 'Ebook Writing',
        icon: <FaTabletAlt />
    }*/,
    // {
    //     id: 12,
    //     title: 'Audio Transcription',
    //     icon: <AiFillAudio />
    // },
    /*{
        id: 13,
        title: 'Blog Posting',
        icon: <FaRegNewspaper />
    },*/

]

// Uncomment your required service.
// Couldn't find the required services? Raise an issue on github at https://github.com/hhhrrrttt222111/developer-portfolio/issues/new
// You can also add on your own 😉.